import React,{useEffect,useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {NavLink} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import About1 from '../img/about-1.png';
import About2 from '../img/about-2.png';
import About3 from '../img/about-3.png';
import About4 from '../img/about-4.png';
//import Team1 from '../img/team-1.jpg';
// import FoodT from '../img/food-truck.png';
import Spin from "./Spinner";
import checkNetworkStatus from './CheckNetwork';
//menu
import vadapav from "../img/menu/vadapav.png";
import mumvadapav from "../img/menu/mumvadapav.png";
import mumMasalaSand from "../img/menu/mumMasalaSand.png";
import dabeli from "../img/menu/dabeli.jpg";
import tandoorivadapav from "../img/menu/tandoorivadapav.jpg";
import puff from "../img/menu/puff.jpg";
import tandooripuff from "../img/menu/tandooripuff.jpg";
import cheesepuff from "../img/menu/cheesepuff.jpg";
import vegcheesepuff from "../img/menu/vegcheesepuff.jpg";
import frankie from "../img/menu/frankie.jpg";
import butterPb from "../img/menu/butterPb.png";
import cheesePb from "../img/menu/cheesePb.png";
import bmbsand from "../img/menu/bmbsand.jpg";
import paneersand from "../img/menu/paneersand.jpg";
import butterCheesePb from "../img/menu/butterCheesePb.png";
import cheesegrillsand from "../img/menu/cheesegrillsand.jpg";
import pavbhaji from "../img/menu/pavbhaji.jpg";
import tandooriBurger from "../img/menu/tandooriBurger.png";
import noodleBurger from "../img/menu/noodleBurger.png";
import cheeseVp from "../img/menu/cheeseVp.png";
import butterVp from "../img/menu/butterVp.png";
import butterCheeseVp from "../img/menu/butterCheeseVp.png";
import schezwanPuff from "../img/menu/schezwanPuff.png";
import cheeseDabeli from "../img/menu/cheeseDabeli.png";
import butterDabeli from "../img/menu/butterDabeli.png";
import coldcoffee from "../img/menu/coldcoffee.jpg";
import filtercoffee from "../img/menu/coffee.png";
import lemonsoda from "../img/menu/lemonsoda.jpg";
import jaljirasoda from "../img/menu/jaljirasoda.jpg";
import spicypaneer from "../img/menu/spicypaneer.jpg";
import mayoburger from "../img/menu/mayoburger.jpg";
import schzwanburger from "../img/menu/schezwanburger.jpg";
import vegburger from "../img/menu/vegburger.jpg";
import cMilk from '../img/menu/cMilk.png'
import cbMilk from '../img/menu/cbMilk.png'
import oMilk from '../img/menu/oMilk.png'
import sMilk from '../img/menu/sMilk.png';
import tea from '../img/menu/tea.jpg';

import Slide1 from "../img/slide1.JPG";
// import Slide2 from "../img/slide2.JPG";
import slideBurger from "../img/slideBurger.PNG";
import Slide4 from "../img/milkshakes.png";
import Slide5 from "../img/catering.png";
import { HashLink } from 'react-router-hash-link';

const UserHome=(props)=> {
    const [message,setMessage]=useState({});

    const scrollToPosition = (el) => {
        const yOffset = -90; // Adjust this value according to your navbar height or desired offset
        const yPosition = el.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
    };
    const BASEURL=process.env.REACT_APP_BASE_URL;
    
    const steats = ()=>{
        // document.getElementById("navHighlight").style.borderBottom = "2px solid var(--primary)";
        document.getElementById("navHighlight").classList.add("text-with-line");
        document.getElementById("navHighlight2").classList.remove("text-with-line");
        document.getElementById("navHighlight3").classList.remove("text-with-line");
        document.getElementById("navHighlight4").classList.remove("text-with-line");
        document.getElementById("navHighlight5").classList.remove("text-with-line");

        document.getElementById('dispSteats').style.display = 'block'
        document.getElementById('dispDish').style.display = 'none'
        document.getElementById('dispMilk').style.display = 'none'
        document.getElementById('dispFast').style.display = 'none'
        document.getElementById('dispSandwiches').style.display = 'none'
    } 
    const sandwiches = ()=>{
        document.getElementById("navHighlight2").classList.remove("text-with-line");
        document.getElementById("navHighlight3").classList.remove("text-with-line");
        document.getElementById("navHighlight4").classList.remove("text-with-line");
        document.getElementById("navHighlight").classList.remove("text-with-line");
        document.getElementById("navHighlight5").classList.add("text-with-line");

        document.getElementById('dispSteats').style.display = 'none'
        document.getElementById('dispDish').style.display = 'none'
        document.getElementById('dispMilk').style.display = 'none'
        document.getElementById('dispFast').style.display = 'none'
        document.getElementById('dispSandwiches').style.display = 'block'

    } 
    const dishes = ()=>{
        document.getElementById("navHighlight2").classList.add("text-with-line");
        document.getElementById("navHighlight").classList.remove("text-with-line");
        document.getElementById("navHighlight3").classList.remove("text-with-line");
        document.getElementById("navHighlight4").classList.remove("text-with-line");
        document.getElementById("navHighlight5").classList.remove("text-with-line");

        document.getElementById('dispSteats').style.display = 'none'
        document.getElementById('dispDish').style.display = 'block'
        document.getElementById('dispMilk').style.display = 'none'
        document.getElementById('dispFast').style.display = 'none'
        document.getElementById('dispSandwiches').style.display = 'none'
    }
    const milkshake = ()=>{
        document.getElementById("navHighlight2").classList.remove("text-with-line");
        document.getElementById("navHighlight3").classList.remove("text-with-line");
        document.getElementById("navHighlight").classList.remove("text-with-line");
        document.getElementById("navHighlight5").classList.remove("text-with-line");
        document.getElementById("navHighlight4").classList.add("text-with-line");

        document.getElementById('dispSteats').style.display = 'none'
        document.getElementById('dispDish').style.display = 'none'
        document.getElementById('dispMilk').style.display = 'block'
        document.getElementById('dispFast').style.display = 'none'
        document.getElementById('dispSandwiches').style.display = 'none'

    }
    const fastfood = ()=>{
        
        document.getElementById("navHighlight3").classList.add("text-with-line");
        document.getElementById("navHighlight2").classList.remove("text-with-line");
        document.getElementById("navHighlight").classList.remove("text-with-line");
        document.getElementById("navHighlight4").classList.remove("text-with-line");
        document.getElementById("navHighlight5").classList.remove("text-with-line");

        document.getElementById('dispSteats').style.display = 'none'
        document.getElementById('dispDish').style.display = 'none'
        document.getElementById('dispMilk').style.display = 'none'
        document.getElementById('dispFast').style.display = 'block'
        document.getElementById('dispSandwiches').style.display = 'none'
    }

    const sendMessage = async (e) => {
    setMessage({name:"",email:"",subject:"",msg:""});
    props.setShowSpinner(true);
    e.preventDefault();
    const {name,email,subject,msg} = message;
    const pattern=/^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if(name.trim() === '' || email.trim() === '' || subject.trim() === '' || msg.trim() === ''){
        props.setShowSpinner(false);
        setMessage({name:"",email:"",subject:"",msg:""});
        toast.error("Please fill all the details");
        return;
    }
    if (msg.length >= 400) {
        props.setShowSpinner(false);
         setMessage({name:"",email:"",subject:"",msg:""});
        toast.error("Please write at most 400 characters!");
        return;
    }
    if(!pattern.test(email)){
        props.setShowSpinner(false);
         setMessage({name:"",email:"",subject:"",msg:""});       
        toast.error("Please write a valid email address!");
        return;
    }
    
    else{
        try {
            const response = await fetch(`${BASEURL}/contactUS`, {
              method: 'POST',
              headers:{
                  "Content-Type":"application/json"
                },
              body: JSON.stringify({name,email,subject,msg}),
            });
            props.setShowSpinner(false);
        if (response.status === 201) {
                await new Promise(resolve => setTimeout(resolve, 0)); // Ensure the toast appears
                toast.success('Your inquiry has been submitted successfully!');
            } else if(response.status===401){
                toast.error('Please enter valid email address!');
            }else if(response.status===500){
                toast.error('There is an error sending the message at the moment! Please email your query at "mumbaiyacafe@gmail.com"');
            }
            setMessage({name:"",email:"",subject:"",msg:""});
          } catch (error) {
            alert('There is an error sending the message at the moment! Please email your query at "mumbaiyacafe@gmail.com"');
          }
    } 
    };

    //To check network connection
    useEffect(() => {
        const checkInternetConnection = () => {
          checkNetworkStatus((speed) => {
            if (speed < 10000000) { // Adjust the speed threshold as needed
              props.setShowSpinner(true);
            } else {
              props.setShowSpinner(false);
            }
          });
        };
    
        checkInternetConnection(); // Initial check
        const interval = setInterval(checkInternetConnection, 10000); // Check every 10 seconds
    
        const handleOnline = () => {
          checkInternetConnection();
        };
    
        const handleOffline = () => {
          props.setShowSpinner(true);
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
          clearInterval(interval);
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };
        // eslint-disable-next-line
      }, []);
    
    //To scroll
    useEffect(()=>{
    if(!props.showSpinner){
        const navLinkEls=document.querySelectorAll('.nav-link');
        const divEls=document.querySelectorAll('.section');
        
        let currentDiv = localStorage.getItem('activeLink') || '';
        const handleScroll=()=>{
          divEls.forEach(divEl=>{
              if(window.scrollY >= divEl.offsetTop - window.innerHeight / 2){
              currentDiv=divEl.id;
            }
          })
          localStorage.setItem('activeLink', currentDiv);
    
          navLinkEls.forEach(nl=>{
            if(nl.href.includes(currentDiv)){
              nl.classList.add('active');
            }else {
              nl.classList.remove('active');
            }
          })
        }
        window.addEventListener('scroll',handleScroll);
        handleScroll(); // Initial call to set active section based on the initial scroll position
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }
    // eslint-disable-next-line
    },[]);

    //Adding text-with-line in menu
    useEffect(() => {
    if(!props.showSpinner){
    //window.scrollTo(0, 0);
    document.getElementById("navHighlight").classList.add("text-with-line");
    }
    }, []);

    useEffect(() => {
    if(!props.showSpinner){
        const handleClick = (e) => {
          e.preventDefault();
          document.querySelector(e.target.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
          });
        };
    
        const anchorEls = document.querySelectorAll('a[href^="#"]');
        anchorEls.forEach(anchor => {
          anchor.addEventListener('click', handleClick);
        });
    
        return () => {
          anchorEls.forEach(anchor => {
            anchor.removeEventListener('click', handleClick);
          });
        };
    }
    // eslint-disable-next-line
    }, []);
   
  return (
    <>
    {
        props.showSpinner && <Spin/>
        
    }
    <ToastContainer
    position="top-center"
    autoClose={2000}
    newestOnTop
    closeOnClick={true}
    rtl={false}
    draggable
    pauseOnHover={false}
    theme="dark"/>
    {
        !props.showSpinner && 
    <div className="mw-100 container-xxl bg-white p-0">


        {/* <!----Hero Start --> */}
        <div className="mw-100 container-xxl position-relative p-0 section" id='#home'>
            <div className="mw-100 container-xxl py-4 bg-dark hero-header mb-5">
                <div className="container my-5 py-5">
                    <div className="row align-items-center g-5" style={{marginTop:"-90px"}}>
                        <div className="col-lg-6 text-center text-lg-start miniHero animated slideInLeft">
                            <h1 className="display-3 text-white mb-3">Taste of<br/>
                            India on Wheels
                            </h1>
                            <p className="text-white mb-4 pb-2" style={{textAlign:"justify"}}>Experience the vibrant flavors of India right here in Regina. From spicy street food to delicious traditional dishes, Mumbaiya Cafe brings authentic Indian cuisine to your neighborhood. Join us for a culinary journey you won't forget.</p>
                            <div className='d-flex-inline'>
                            <HashLink to="/##menu" className="btn btn-primary py-sm-3 px-sm-2 me-3 " style={{backgroundColor:"#FFDB58",marginBottom:"0.5rem"}} scroll={scrollToPosition}>Explore our Menu</HashLink>
                            <HashLink to="/##contactUs" className="btn btn-primary py-sm-3 px-sm-2 me-3 " style={{backgroundColor:"#FFDB58",marginBottom:"0.5rem"}} scroll={scrollToPosition}>Book us</HashLink>
                            </div>
                            {/* <HashLink to="/##menu" className="btn btn-primary py-sm-3 px-sm-5 me-3 " style={{backgroundColor:"#FFDB58",marginBottom:"0.5rem"}} scroll={scrollToPosition}>Explore our Menu</HashLink> */}
                            {/* <HashLink to="/##menu" className="btn btn-primary py-sm-3 px-sm-5 me-3 " style={{backgroundColor:"#FFDB58"}} scroll={scrollToPosition}>Explore our Menu</HashLink> */}
                        </div>
                        <div className="col-lg-6 text-center text-lg-end overflow-hidden miniSlider animated slideInRight">
                <div id="carouselExampleControls" className="carousel slide position-relative p-0" data-bs-ride="carousel">
                    <div className="carousel-inner">
                    {/* <div className="carousel-item active">
                        <img src={closedSlide2} alt="img" className="d-block carousel-image"  style={{objectFit:"contain"}}></img>
                    </div> */}
                    <div className="carousel-item active">
                        <img src={slideBurger} alt="img" className="d-block carousel-image"  style={{objectFit:"contain"}}></img>
                    </div>
                    <div className="carousel-item">
                        <img src={Slide5} alt="img" className="d-block carousel-image"  style={{objectFit:"contain"}}></img>
                    </div>
                    <div className="carousel-item">
                        <img src={Slide4} alt="img" className="d-block carousel-image"  style={{objectFit:"contain"}}></img>
                    </div>
                    
                    <div className="carousel-item">
                        <img src={Slide1} alt="img" className="d-block carousel-image"  style={{objectFit:"contain"}}></img>
                    </div>
                    {/* <div className="carousel-item">
                        <img src={Slide2} alt="img" className="d-block carousel-image"  style={{objectFit:"contain"}}></img>
                    </div> */}
                    {/* <div className="carousel-item">
                        <img src={Slide3} alt="img" className="d-block carousel-image"  style={{objectFit:"contain"}}></img>
                    </div> */}
                    
                </div>
                <button className="carousel-control-prev" style={{color:"#0F172B",left:"0"}} type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                     <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" style={{color:"#0F172B",right:"0"}} type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
                </button>
                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Navbar & Hero End -->


        <!-- About Start --> */}
        <div className="container-xxl py-5 section" id="#aboutUs" style={{marginTop:"-100px"}}>
            <div className="container" style={{marginTop:"-50px",marginBottom:"50px"}}>
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6">
                        <div className="row g-3">
                            <div className="col-6 text-start">
                                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.1s" src={About1} alt='img' />
                            </div>
                            <div className="col-6 text-start">
                                <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.3s" src={About2} alt='img'  style={{marginTop:"25%"}}/>
                            </div>
                            <div className="col-6 text-end">
                                <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.5s" src={About3} alt='img' />
                            </div>
                            <div className="col-6 text-end">
                                <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.7s" src={About4} alt='img' />
                            </div>
                        </div>
                    </div>
                
                    <div className="col-lg-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="section-title ff-secondary text-heading fw-normal">About Us</h5>
                        <h1 className="mb-4">Welcome to <i className="fa fa-utensils text-heading me-2"></i>Mumbaiya Cafe</h1>
                        <h4 className="mb-4">Our Promise: <span className='text-heading'>Quality</span>,<span className='text-heading'> Quantity</span> and <span className='text-heading'>Price</span> <i className="fas fa-smile"></i></h4>
                    <div className="qualities-container">         
                        <div className="quality">
                            <span className="circle">Top-notch Quality</span>
                            <p>Because our ingredients are so fresh, they probably have gym memberships.</p>
                        </div>
                        <div className="quality">
                            <span className="circle">Generous Quantity</span>
                            <p>Our servings are so hearty, you'll need extra room to fit it all.</p>
                        </div>
                        <div className="quality">
                            <span className="circle">Unbeatable Price</span>
                            <p>Deliciousness that won't break the bank – your taste buds and wallet will thank you!</p>
                        </div>
                    </div>
                    </div>

                </div>
            </div>
        </div>
        {/* <!-- About End -->


        <!-- Menu Start --> */}
        <div className="container-xxl py-3 section mw-100" id="#menu" style={{backgroundColor:"#0F172B",marginTop:"-100px"}}>
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="section-title2 ff-secondary text-center text-primary fw-normal" style={{marginBottom:"50px"}}>Our Menu</h3>
                </div>
                {/* <div className="tab-class text-center wow fadeInUp" data-wow-delay="0.1s">
                <h1 className="ff-secondary text-center text-primary fw-normal" style={{marginBottom:"50px"}}>Revealing soon..</h1>
                </div> */}
            </div>
            <div className="tab-class text-center wow fadeInUp" data-wow-delay="0.1s">
{/* <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-5" style={{display:"flex !important",justifyContent:"space-evenly !important",alignItems:"center"}}> */}
<ul className="nav nav-pills border-bottom mb-5 d-flex flex-wrap justify-content-center align-items-center">
<li className="nav-item underline" id='navHighlight'>
        <NavLink onClick={steats} className="d-flex align-items-center text-start ms-0 pb-3 active" data-bs-toggle="pill" href="#tab-1">
            <i className="fa fa-utensils fa-2x text-primary"></i>
            <div className="ps-3">
                <small className="text-body">Popular</small>
                <h6 className="mt-n1 mb-3 text-primary">StreetEats</h6>
            </div>
        </NavLink>
    </li>
    <li className="nav-item underline" id='navHighlight5'>
        <NavLink onClick={sandwiches} className="d-flex align-items-center text-start ms-0 pb-3 active" data-bs-toggle="pill" href="#tab-2">
            <i className="fa fa-bread-slice fa-2x text-primary"></i>
            <div className="ps-3">
                <small className="text-body">Superb</small>
                <h6 className="mt-n1 mb-3 text-primary">Sandwiches</h6>
            </div>
        </NavLink>
    </li>
    <li className="nav-item underline" id='navHighlight3'>
        <NavLink onClick={fastfood} className="d-flex align-items-center text-start me-0 pb-3" data-bs-toggle="pill" href="#tab-3">
            <i className="fa fa-hamburger fa-2x text-primary"></i>
            <div className="ps-3">
                <small className="text-body">Big Bite</small>
                <h6 className="mt-n1 mb-3 text-primary">Burgers</h6>
            </div>
        </NavLink>
    </li>
    <li className="nav-item underline" id='navHighlight2'>
        <NavLink onClick={dishes} className="d-flex align-items-center text-start me-0 pb-3" data-bs-toggle="pill" href="#tab-4">
            <i className="fa fa-concierge-bell fa-2x text-primary"></i>
            <div className="ps-3">
                <small className="text-body">Delicious</small>
                <h6 className="mt-n1 mb-3 text-primary">Pavbhajis</h6>
            </div>
        </NavLink>
    </li>
    <li className="nav-item underline" id='navHighlight4'>
        <NavLink onClick={milkshake} className="d-flex align-items-center text-start pb-3" data-bs-toggle="pill" href="#tab-5">
                <i className="fa fa-coffee fa-2x text-primary"></i>
            <div className="ps-3">
                <small className="text-body">Bursting</small>
                <h6 className="mt-n1 mb-3 text-primary">Beverages</h6>
            </div>
        </NavLink>
    </li>
</ul>

<div id='dispSteats' className="tab-content">
    <div id="tab-1" className="tab-pane fade show p-0 active">
        <div className="row g-4">
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={mumvadapav} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Mumbaiya Vadapav</span>
                            <span className="text-primary">$2.99</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={vadapav} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Regular Vadapav</span>
                            <span className="text-primary">$3.49</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={cheeseVp} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Cheese Vadapav</span>
                            <span className="text-primary">$3.99</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={butterVp} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Butter Vadapav</span>
                            <span className="text-primary">$3.99</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={butterCheeseVp} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Butter Cheese Vadapav</span>
                            <span className="text-primary">$4.49</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={tandoorivadapav} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Tandoori vada pav</span>
                            <span className="text-primary">$3.99</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={dabeli} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Dabeli</span>
                            <span className="text-primary">$3.49</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={dabeli} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Butter Dabeli</span>
                            <span className="text-primary">$3.99</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={cheeseDabeli} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Cheese Dabeli</span>
                            <span className="text-primary">$3.99</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={butterDabeli} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Butter Cheese Dabeli</span>
                            <span className="text-primary">$4.49</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={puff} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Homemade Puff</span>
                            <span className="text-primary">$2.99</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={tandooripuff} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Tandoori puff</span>
                            <span className="text-primary">$3.99</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={cheesepuff} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Cheese puff</span>
                            <span className="text-primary">$3.99</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={vegcheesepuff} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Veg cheese puff</span>
                            <span className="text-primary">$4.49</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={schezwanPuff} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Schezwan puff</span>
                            <span className="text-primary">$3.99</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={frankie} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Frankie</span>
                            <span className="text-primary">$4.99</span>
                        </h5>
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={samosapav} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Samosa pav</span>
                            <span className="text-primary">$3</span>
                        </h5>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
</div>

<div id='dispSandwiches' className="tab-content">
    <div id="tab-1" className="tab-pane fade show p-0 active">
        <div className="row g-4">
        <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={mumMasalaSand} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Bombay Masala Sandwhich</span>
                            <span className="text-primary">$6.49</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={paneersand} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Paneer Makhani Sandwhich</span>
                            <span className="text-primary">$7.99</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={bmbsand} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Bombay Club Sandwhich (2 layers)</span>
                            <span className="text-primary">$7.99</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={cheesegrillsand} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Cheese grill sandwhich</span>
                            <span className="text-primary">$5.99</span>
                        </h5>                                         
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={fried} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Fries</span>
                            <span className="text-primary">$3</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={periperi} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Piri piri fries</span>
                            <span className="text-primary">$3.50</span>
                        </h5>
                        
                    </div>
                </div>
            </div> */}
        </div>
    </div>
</div>

<div id='dispFast' className="tab-content">
    <div id="tab-1" className="tab-pane fade show p-0 active">
        <div className="row g-4">
            
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={vegburger} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Veggie Patty Burger</span>
                            <span className="text-primary">$5.99</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={schzwanburger} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Schezwan Burger</span>
                            <span className="text-primary">$6.49</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={mayoburger} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Veggie Mayo Burger</span>
                            <span className="text-primary">$6.49</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={tandooriBurger} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Tandoori Burger</span>
                            <span className="text-primary">$6.49</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
           
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={spicypaneer} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Spicy Paneer Burger</span>
                            <span className="text-primary">$7.49</span>
                        </h5>
                        
                    </div>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={noodleBurger} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Noodle Burger</span>
                            <span className="text-primary">$8.99</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={fried} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Fries</span>
                            <span className="text-primary">$3</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={periperi} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Piri piri fries</span>
                            <span className="text-primary">$3.50</span>
                        </h5>
                        
                    </div>
                </div>
            </div> */}
        </div>
    </div>
</div>

<div id='dispDish' className="tab-content">
    <div id="tab-1" className="tab-pane fade show p-0 active">
        <div className="row g-4">
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={pavbhaji} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Regular Pavbhaji</span>
                            <span className="text-primary">$7.99</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={butterPb} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Butter Pavbhaji</span>
                            <span className="text-primary">$8.49</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={cheesePb} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Cheese Pavbhaji</span>
                            <span className="text-primary">$8.99</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={butterCheesePb} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Butter Cheese Pavbhaji</span>
                            <span className="text-primary">$9.99</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={surti} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Surti Alu Puri (12 pcs)</span>
                            <span className="text-primary">$8</span>
                        </h5>
                    </div>
                </div>
            </div> */}
            {/* <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={momo} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Veg Steamed Momos</span>
                            <span className="text-primary">$8</span>
                        </h5>
                        
                    </div>
                </div>
            </div> */}
            {/* <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={paneermomo} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Paneer Steamed Momos</span>
                            <span className="text-primary">$10</span>
                        </h5>
                        
                    </div>
                </div>
            </div> */}
            {/* <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={bhoongdabataka} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Bhoongda Batata</span>
                            <span className="text-primary">$8</span>
                        </h5>
                        
                    </div>
                </div>
            </div> */}
           
        </div>
    </div>
</div>

<div id='dispMilk' className="tab-content">
    <div id="tab-1" className="tab-pane fade show p-0 active">
        <div className="row g-4">
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={tea} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Masala Tea</span>
                            <span className="text-primary">$1.99</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={coldcoffee} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Cold coffee</span>
                            <span className="text-primary">$3.99</span>
                        </h5>                       
                    </div>
                </div>
            </div>
            {/* <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={filtercoffee} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Filter coffee</span>
                            <span className="text-primary">$2</span>
                        </h5>
                        
                    </div>
                </div>
            </div> */}
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={lemonsoda} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Lemon soda</span>
                            <span className="text-primary">$2.49</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={jaljirasoda} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Jaljira soda</span>
                            <span className="text-primary">$2.99</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={cMilk} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Chocolate Milkshake</span>
                            <span className="text-primary">$4</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={cbMilk} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Chocolate Brownie Milkshake</span>
                            <span className="text-primary">$5</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={oMilk} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Oreo Milkshake</span>
                            <span className="text-primary">$5</span>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <img className="flex-shrink-0 img-fluid rounded" src={sMilk} alt="" style={{width: "80px"}}/>
                    <div className="w-100 d-flex flex-column text-start ps-4">
                        <h5 className="d-flex justify-content-between border-bottom pb-2 text-primary fs1rem">
                            <span>Strawberry Milkshake</span>
                            <span className="text-primary">$5</span>
                        </h5>                   
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
            </div>
        </div>
        {/* <!-- Menu End -->


        <!-- Contact Us Start --> */}
        
        <div className="container-xxl py-5 section" id='#contactUs' style={{marginTop:"-50px"}}>
        {/* <div className="container-xxl py-5 section" id='#contactUs' style={{ backgroundImage: `url(${ContactBG})`,marginTop:"-50px"}}> */}
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="section-title ff-secondary text-center text-heading2 fw-normal">Contact Us</h5>
                    <h1 className="mb-3">Contact For Any Inquiry</h1>
                </div>
                <div className="row g-4">
                    <div className="col-md-6 wow fadeIn smallMap" data-wow-delay="0.1s" style={{height:"400px"}}>
                        <iframe className="position-relative rounded w-100 h-100"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5081.499994469602!2d-104.55735912456555!3d50.44575718770311!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x531c1f94eb9fdbcb%3A0xfb5cf6bc5efb997!2sMumbaiya%20Cafe%20-%20Indian%20Street%20Food!5e0!3m2!1sen!2sca!4v1718334857992!5m2!1sen!2sca" 
                            allowFullScreen="" aria-hidden="false" tabIndex="0" title='map'></iframe>
                    </div>
                    <div className="col-md-6">
                        <div className="wow fadeInUp" data-wow-delay="0.2s">
                            <form className='miniForm'>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control mdQuery" id="name" name="name" value={message.name} onChange={(e) => {setMessage((om) => ({...om, name: e.target.value}))}}
                                            required  autoComplete="off"/>
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="email" className="form-control mdQuery" id="email" name="email" value={message.email} onChange={(e) => {setMessage((om) => ({  ...om,  email: e.target.value}));}} required  autoComplete="off"/>
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="text" className="form-control mdQuery" id="subject" name="subject" value={message.subject} onChange={(e) => {setMessage((om) => ({  ...om,  subject: e.target.value}));}} required  autoComplete="off"/>
                                            <label htmlFor="subject">Subject</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control mdQuery" maxLength="400" id="msg"  name="msg" value={message.msg} onChange={(e) => {setMessage((om) => ({  ...om,  msg: e.target.value}));}} 
                                            style={{height: "150px"}} required  autoComplete="off"></textarea>
                                            <label htmlFor="message">Message (At most 400 characters)</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary w-100 py-3" type='button' onClick={sendMessage}>Send Message</button>
                                        
                                    </div>
                                    <div className="d-flex flex-column bd-highlight mb-3">
                                    <div className="p-1"><p><i className="fa fa-phone text-heading2 me-2"></i><a href="tel:+16395541060" style={{color: "chocolate",fontFamily:"Nunito, sans-serif"}}>+1 639 554 1060</a></p></div>
                                    <div className="p-1"><p><i className="fa fa-envelope-open text-heading2 me-2"></i><a style={{color:"chocolate",fontFamily:"Nunito, sans-serif"}} href="mailto:mumbaiyacafe@gmail.com">mumbaiyacafe@gmail.com</a></p></div>
                                    <div className="p-1"><i className="fa fa-map-marker-alt me-3 text-heading2"></i><a style={{color: "chocolate",fontFamily:"Nunito, sans-serif"}} href='https://maps.app.goo.gl/uJDcLe5rtowB9cGB7' target='_blank' rel="noreferrer">West Parking Lot, 2223 Victoria Ave E, Regina, SK S4N 6E4</a></div>
                                    </div>       
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       {/*<!-- Contact Us End --> */}

    </div>
    }
    </>
  )
}

export default UserHome
